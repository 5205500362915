const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const formatDate = (date) => {
  const dateObj = new Date(date);

  return `${months[dateObj.getMonth()]} ${dateObj.getFullYear()}`;
};

const formatFullDate = (date) => {
  const dateObj = new Date(date);

  return `${dateObj.getDate()} ${
    months[dateObj.getMonth()]
  } ${dateObj.getFullYear()}`;
};

export default {
  formatDate,
  formatFullDate,
};
